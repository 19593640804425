import axios from 'axios'

export class PlanejamentoPedagogico {

  static async buscarDataDisciplina(payload) {
    return axios.get('planejamento/pedagogico/' + payload.segmento + '/' + payload.serie_id + '/' + payload.disciplina_id + '/datas/' + payload.dia + '/' + payload.mes + '/' + payload.ano);
  }

  static async buscarDataDisciplinaMes(payload) {
    return axios.get('planejamento/pedagogico/mes/' + payload.segmento + '/' + payload.serie_id + '/' + payload.disciplina_id + '/datas/' + payload.mes + '/' + payload.ano);
  }

  static async buscarPlanejamento(ano) {
    return axios.get('/' + ano + '/planejamento/pedagogico/');
  }

  static async buscarPlanejamentoConfig(payload) {
    return axios.post('planejamento/pedagogico/config', payload);
  }

  static async cadastrar(payload) {
    return axios.post('planejamento/pedagogico', payload);
  }

  static async obtemUm(id) {
    return axios.get('planejamento/pedagogico/' + id)
  }

  static async alterar(payload) {
    return axios.put('/planejamento/pedagogico/' + payload.id, payload)
  }

  static async remover(id) {
    return axios.delete('/planejamento/pedagogico/' + id)
  }
  // professor
  static async addPlanejamentoProf(payload) {
    const ano = sessionStorage.getItem("anoSelecionado");
    const response = await axios.post(`/planejamento/professor/ano/${ano}`, payload);
    return response;
  }

  static async editarPlanejamentoProf(payload) {
    return axios.put('planejamento/professor/' + payload.id, payload)
  }

  static async deletarPlanejamentoProf(id) {
    return axios.delete('planejamento/professor/' + id)
  }

  static async obtemPlanejamentoProf(payload) {
    return axios.get(payload.ano + '/planejamento/professor/' + payload.professor_id + '/' + payload.disciplina_id + '/' + payload.turma_id)
  }

  static async obtemPlanejamentoProfMes(payload) {
    return axios.get(payload.ano + '/planejamento/professor/' + payload.professor_id + '/' + payload.disciplina_id + '/' + payload.turma_id + '/' + payload.mes)
  }

  static async obtemPlanejamentoMensal(payload) {
    return axios.get(payload.ano + '/planejamento/mensal/' + payload.disciplina_id + '/' + payload.turma_id + '/' + payload.mes)
  }

}
